import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { validationRegExp } from '../../../locales/locales-constants';
import { AdminServicesService } from '../admin-services/admin-services.service';
import Swal from 'sweetalert2';
import { messages } from '../../../locales/locales-messages';
import { CommonService } from '../../../services/common.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
forgotPasswordForm  : FormGroup;
  constructor(public modal: NgbActiveModal,
    private fb: FormBuilder,
    private adminService: AdminServicesService,
    private commonService: CommonService) { }

  ngOnInit() {
    this.initializeForm();
  }

  initializeForm(){
    this.forgotPasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(validationRegExp.emailRegExp), Validators.maxLength(30)]]
    });
  }

  get f() { return this.forgotPasswordForm.controls; }

  sendEmail(){
    if(this.forgotPasswordForm.valid){
      const req ={
        email:this.forgotPasswordForm.value.email
      }
      this.adminService.forgotPassword(this.commonService.trimAllValues(req)).subscribe(res => {
        if (res.success) {
          this.modal.close();
          Swal.fire({
            ...messages.success,
            icon: 'success',
          })
        }
        else {
          Swal.fire({
            ...messages.failed,
            icon: 'error',
          })
        }
      },
        err => {
          Swal.fire({
            ...messages.failed,
            icon: 'error',
          })
        })
    }
  }
}
