import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from "@angular/common/http";

import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { CommonService } from "../services/common.service";
import { environment } from "src/environments/environment.prod";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(public commonService: CommonService) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let authToken: any;
    if (this.secureReq(request)) {
      authToken = this.commonService.getSuperAdminAuthToken.token;
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${authToken}`
        }
      });
    }
    return next.handle(request).pipe(
      catchError(err => {
        if (err.status === 401) {
          location.reload(true);
        }
        return this.handleError(err);
      })
    );
  }

  /**
   * 	Handle serve error
   * @param error
   */
  handleError(error) {
    let errorMessage: any;
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = {
        Error: error.error,
        ErrorCode: error.error.code,
        ErrorMessage: error.error.message,
        Success: error.error.success,
        Data: error.error.data
      };
    }
    return throwError(errorMessage);
  }

  secureReq(req: any) {
    if (req.url.includes("login")) {
      return false;
    }
    if (req.url.includes("signup")) {
      return false;
    }
    if (req.url.includes("forgot-password")) {
      return false;
    }
    if (req.url.includes("forgot-password")) {
      return false;
    }
    return true;
  }
}
