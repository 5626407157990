import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { StripeElementComponent } from "./stripe/stripe-element/stripe-element.component";

const routes: Routes = [
  { path: "payment", component: StripeElementComponent },
  { path: "admin", loadChildren: "./module/admin/admin.module#AdminModule" },
  { path: "", loadChildren: "./module/admin/admin.module#AdminModule" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
