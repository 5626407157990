import { Injectable } from '@angular/core';
import { HttpBaseService } from './http-base.service';
import { Subject } from 'rxjs';
import { LoaderService } from './loader-service/loader.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { finalize } from 'rxjs/operators';
declare var $: any;

let api_url = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  private authUserSubject = new Subject<any>();
  public authUser = this.authUserSubject.asObservable();

  private authSuperAdminSubject = new Subject<any>();
  public authSuperAdmin = this.authSuperAdminSubject.asObservable();

  private purchasedPlanSub = new Subject<any>();
  public purchasedPlan = this.purchasedPlanSub.asObservable();
  public profile: any;

  constructor(private loaderService: LoaderService,
    private http: HttpClient) { }

  /**TO process API response */
  processAPIResponse(data) {
    if (data.status) {
      return data;
    } else {
      return false;
    }
  }

  /**
  * Get JWT Auth Token
  */
  get getAuthToken() {
    return JSON.parse(localStorage.getItem('authUser')).token;
    //return this.authUserSubject.value.token;
  }
  get getOrgAuthToken() {
    return JSON.parse(localStorage.getItem('authUser')).organizationToken;
    //return this.authUserSubject.value.token;
  }
  get getSuperAdminAuthToken() {
    return JSON.parse(localStorage.getItem('authSuperAdmin')).token;
  }


  /**
 * Set authenticated user to loacal storage
 * @param data 
 */
  setAuthUser(data: any) {
    localStorage.setItem('authUser', JSON.stringify(data));
    this.authUserSubject.next(data);
  }

  /**
* Set authenticated superadmin to local storage
* @param data 
*/
  setAuthSuperAdmin(data: any) {
    this.authSuperAdminSubject.next(data);
    localStorage.setItem('authSuperAdmin', JSON.stringify(data));
  }

  /**
 * Get authenticated user
 */
  get getAuthUser() {
    return JSON.parse(localStorage.getItem('authUser'));
    //return this.authUserSubject.value;
  }

  /**
* Get authenticated superadmin
*/
  get getAuthSuperAdmin() {
    return JSON.parse(localStorage.getItem('authSuperAdmin'));
  }

  logout() {
    /**
     * Remove user from local storage to log user out
     */
    localStorage.removeItem('authUser');
    this.authUserSubject.next(null);
  }

  logoutSuperAdmin() {
    /**
     * Remove superadmin from local storage to log user out
     */
    localStorage.removeItem('authSuperAdmin');
    this.authSuperAdminSubject.next(null);
  }

  setPurchasedPlan(plan: any) {
    this.purchasedPlanSub.next(plan);
  }

  validateImage(name: string) {
    var ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() == 'png' || ext.toLowerCase() == 'jpeg' || ext.toLowerCase() == 'jpg') {
      return true;
    } else {
      return false;
    }
  }
  /**
 * Upload Image
 */

  imageUpload(file: any) {
    const formData = new FormData();
    formData.append('file', file);
    this.loaderService.show();
    return this.http.post(api_url + '/uploadImage', formData).pipe(
      finalize(() => {
        this.loaderService.hide()
      }));;
  }

  trimAllValues(obj) {
    Object.keys(obj).map(k => obj[k] = typeof obj[k] === 'string' ? obj[k].trim() : obj[k]);
    return obj;
  }

  tableResponsive() {
    setTimeout(() => {
      $(".table:not(.nojs-table)").jsmartable();
    });
  }
  linkScroller() {
    $('.link-navbar').owlCarousel('destroy');
    setTimeout(() => {
      $(".link-navbar").owlCarousel({
        margin: 25,
        autoWidth: true,
        nav: true,
        dots: false,
        mouseDrag: false,
        navText: [
          "<i class='icon-arrow-left'></i>",
          "<i class='icon-arrow-right-line'></i>"
        ]
      });
    });
  }
}
