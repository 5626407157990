import { Component } from "@angular/core";
import { CommonService } from "./services/common.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {
  title = "super-admin";
  constructor(private commonService: CommonService, private router: Router) {
    if (
      window.location.pathname == "/admin" ||
      window.location.pathname == "/admin/"
    ) {
      const authUser = this.commonService.getAuthSuperAdmin;
      if (authUser) {
        this.router.navigate(["/organization"]);
      }
    }
  }
}
