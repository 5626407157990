import { Component, OnInit, ChangeDetectorRef, ElementRef, ViewChild, OnDestroy, AfterViewInit, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpBaseService } from "../../services/http-base.service";
import Swal from 'sweetalert2';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';


declare var stripe: any;
declare var elements: any;

@Component({
  selector: 'app-stripe-element',
  templateUrl: './stripe-element.component.html',
  styleUrls: ['./stripe-element.component.scss']
})
export class StripeElementComponent implements AfterViewInit, OnDestroy {

  @ViewChild('cardInfo') cardInfo: ElementRef;
  @Input() plan;

  card: any;
  cardHandler = this.onChange.bind(this);
  error: string;

  constructor(
    private cd: ChangeDetectorRef,
    private httpBaseService: HttpBaseService,
    public modal: NgbActiveModal,
    private router: Router
  ) { }

  // ngOnInit() {
  // }

  ngAfterViewInit() {
    const style = {
      base: {
        lineHeight: '24px',
        fontFamily: 'monospace',
        fontSmoothing: 'antialiased',
        fontSize: '19px',
        '::placeholder': {
          color: 'purple'
        }
      }
    };

    this.card = elements.create('card', { style });
    this.card.mount(this.cardInfo.nativeElement);

    this.card.addEventListener('change', this.cardHandler);
  }

  ngOnDestroy() {
    this.card.removeEventListener('change', this.cardHandler);
    this.card.destroy();
  }

  onChange({ error }) {
    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
    }
    this.cd.detectChanges();
  }

  async onSubmit(form: NgForm) {
    try {
      const { token, error } = await stripe.createToken(this.card);
      if (error) {
        console.log('Something is wrong:', error);
        Swal.fire(
          '',
          'Payment failled',
          'error'
        )
      } else {
        console.log('Success!', token);
        console.log(this.plan);
        this.httpBaseService.postApi1('/new-user-subscription', { token: token.id, stripe_planId: this.plan.stripe_planId })
          .subscribe(result => {
            console.log('result', result);
            Swal.fire(
              '',
              'Payment success',
              'success'
            )
            this.modal.close();
            location.reload();
            this.router.navigate(['/organization/product/1']);
          })
        // ...send the token to the your backend to process the charge
      }
    } catch (error) {
      Swal.fire(
        '',
        error.message,
        'error'
      )
      console.log('Something is wrong:', error);
    }
  }


}
