$(function () {
  // File upload
  $(document).on('click', '.upload-btn,.browse', function () {
    $(this).parent().find('.file').trigger('click');
  });

  // Responsive menu toggle
  $(document).on('click', '.burger-menu-mobile', function () {
    $('.sidebar-menu').appendTo($('.scroll-window'));
    $('.sidebar-wrapper').removeAttr('style');
    $(this).toggleClass("menu-open");
    $('.sidebar-wrapper').toggleClass('open');
    $('body').append("<div class='sidebar-overlay'></div>");
    $('body').removeClass("sidebar-mini");
  });

  $(document).on('click', '.sidebar-overlay,.sidebar-menu li a, .sidebar-wrapper .navbar-brand', function () {
    $('.burger-menu-mobile').removeClass("menu-open");
    $('.sidebar-wrapper').removeClass('open');
    $('.sidebar-overlay').remove();
  });

  // Sidebar Toggle on Desktop
  $(document).on('click', '.burger-menu-desktop', function () {
    window.scrollTo(0, 0);
    $('body').toggleClass("sidebar-mini");
  });

  // Sidebar Tootltip
  $(document).on('mouseenter', '.sidebar-menu li a', function () {
    $('body').addClass('tooltip-custom');
    $('[data-toggle="tooltip"]').tooltip();
  });
  $(document).on('mouseleave', '.sidebar-menu li a', function () {
    $('body').removeClass('tooltip-custom');
    $('[data-toggle="tooltip"]').tooltip('hide');
  });

  $(document).on('click', '.scroll-edit,.scroll-link-bottom', function () {
    $('html, body').animate({
      scrollTop: $(".target-el").offset().top
    }, 500);
  });

  // Custom Tab
  $(document).on('click', '.tab-custom .nav-item a', function () {
    var tab_id = $(this).attr('data-tab');
    $('.tab-custom .nav-item a').removeClass('active');
    $('.tab-content .tab-pane').removeClass('active');
    $(this).addClass('active');
    $("#" + tab_id).addClass('active');
  });

  // Sorting table
  $(document).on('click', '.sorting', function () {
    var state = this.className.indexOf('sort-asc') > -1;
    $(this).siblings().removeClass('sort-asc sort-desc')
    $(this).toggleClass('sort-asc', !state).toggleClass('sort-desc', state);
  });
})