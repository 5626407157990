import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgxMaskModule } from "ngx-mask";
import { HttpBaseService } from "./services/http-base.service";
import { AuthInterceptor } from "./auth-interceptor/auth.interceptor";
import { StripeElementComponent } from "./stripe/stripe-element/stripe-element.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgwWowModule } from "ngx-wow";
import { LoaderComponent } from "./loader/loader.component";
@NgModule({
  declarations: [AppComponent, StripeElementComponent, LoaderComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxMaskModule.forRoot(),
    NgbModule
  ],
  exports: [StripeElementComponent],
  providers: [
    HttpBaseService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
