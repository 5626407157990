export const defaultImage = "assets/images/user.svg";
export const validationRegExp = {
    inputRegValidation: "^[ A-Za-z0-9_@.!?/#&%$(){}~`|:;',<>+-=\n]*",
    onlySpaceNotAllowed: ".*\\S.*",
    emailRegExp: '[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}',
    phoneNumberRegExp: /^[0-9]{8,15}$/,
    //digitOnlyRegExp: /^[1-9]\d*(\.\d{1,2})?$/,
    digitOnlyRegExp: '[0-9]+(\.[0-9][0-9]?)?',
    nameRegExp: "^[ A-Za-z0-9_@.()',-]*",
    digitWithoutDecimal: /^\d+$/,
    spaceNotAllowed:/^\S*$/,
  };
  
  export const page = {
    pageNo: 1,
    pageSize: 10,
  };