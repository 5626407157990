import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { retry, catchError, finalize } from "rxjs/operators";
import { Router } from "@angular/router";
import { CommonService } from "../services/common.service";
import { LoaderService } from "../services/loader-service/loader.service";

let api_url = environment.apiUrl;
let super_admin_url = environment.superadmin_url;

@Injectable()
export class HttpBaseService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private commonService: CommonService,
    private loaderService: LoaderService
  ) {}

  /** Function used for setting headers for api */
  private headersOptions(): HttpHeaders {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set("Access-Control-Allow-Origin", "*");
    httpHeaders = httpHeaders.set(
      "Access-Control-Allow-Headers",
      "Content-Type"
    );
    httpHeaders = httpHeaders.set("Access-Control-Allow-Credentials", "true");
    // httpHeaders = httpHeaders.set('x-functions-key', 'nZ62pH/dPD89YnY2MJoXs5IwCMQZoNI9G62GJhCIROQ3BEbC6Li7lw==');
    return httpHeaders;
  }

  /** Used for calling GET API */
  public getApi(route: string, options = {}): Observable<any> {
    return this.http.get<any>(route, { params: options });
  }

  public getApi1(route: string): Observable<any> {
    return this.http
      .get<any>(api_url + route, { headers: this.headersOptions() })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  /** Used for calling GET API */
  public get(url: string, options = {}): Observable<any> {
    return this.http.get<any>(api_url + url);
  }

  /**Used for calling POST API */
  public postApi(route: string, body: any): Observable<any> {
    return this.http
      .post<any>(route, body, { headers: this.headersOptions() })
      .pipe(retry(1), catchError(this.handleError));
  }

  public postApi1(route: string, body: any): Observable<any> {
    this.loaderService.show();
    return this.http
      .post<any>(api_url + route, body, { headers: this.headersOptions() })
      .pipe(
        retry(1),
        finalize(() => {
          this.loaderService.hide();
        })
        // catchError(this.handleError)
      );
  }

  public deleteApi(route: string, body: any): Observable<any> {
    return this.http
      .put<any>(api_url + route, body, { headers: this.headersOptions() })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  /**Used for calling PUT API */
  public putApi(route: string, body: any): Observable<any> {
    return this.http
      .put<any>(route, body, { headers: this.headersOptions() })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }

  /**Used for calling PUT API */
  public putApi1(route: string, body: any): Observable<any> {
    return this.http
      .put<any>(super_admin_url + route, body, {
        headers: this.headersOptions()
      })
      .pipe(
        retry(1)
        // catchError(this.handleError)
      );
  }
  // Error handling
  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    this.router.navigate(["error"]);
    return throwError(errorMessage);
  }
}
